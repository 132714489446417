// theme.js (you can call it whatever you want)

//color palette
var primaryColor = 'bg-black'
var secondaryColor = 'bg-white'

//hovers
var primaryHover = 'hover:bg-rose-400'

export const ButtonType = {
    primary: `${primaryColor} ${primaryHover} text-white rounded`,
};

export const ButtonSize = {
  sm: "py-0.5 px-1 text-xs",
  md: "py-1 px-2"
}


export const ModalType = {
  login: `absolute top-0 bottom-0 left-0 right-0 w-1/4 bg-sky-800 m-auto h-1/6 flex flex-col justify-center px-10 gap-y-2`,
  artwork: `absolute top-0 bottom-0 left-0 right-0 w-11/12 m-auto bg-black md:max-w-max max-h-min backdrop-blur-md`,
  artist: "py-1 px-2"
}


export const ModalEffect = {
  blur: `fixed w-screen h-screen overflow-hidden backdrop-blur-xl`,
 
}


