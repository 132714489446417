import {ModalType, ModalEffect} from './theme';

function Chip({effect, type, children, onClick}) {

  // This can be improved. I’m keeping it simple here by joining two strings.
  const classNames = ModalType[type];
  const overlay = ModalEffect[effect]

  return (
    <div onClick={onClick} className={overlay} >
      <div onClick={(e) => e.stopPropagation()} className={classNames}>
        {children}
      </div>
    </div>
  )
}
export default Chip;
