import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'




const firebaseConfig = {
    apiKey: "AIzaSyDTPjhXQqrICUVu9QKuAeHqFpl0e-HdZRA",
    authDomain: "monet-vangogh.firebaseapp.com",
    projectId: "monet-vangogh",
    storageBucket: "monet-vangogh.appspot.com",
    messagingSenderId: "979348979501",
    appId: "1:979348979501:web:66fbde05945541ee781da4"
  };
  // init firebase
firebase.initializeApp(firebaseConfig)

// init services
const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()
const projectStorage = firebase.storage()


// timestamp
const timestamp = firebase.firestore.Timestamp

export { projectFirestore, projectAuth, timestamp, projectStorage, firebase}

  

  
