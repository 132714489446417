import { Icon } from "@iconify/react";


import { useAuthContext } from './hooks/useAuthContext.js'

//firebase
import { firebase } from './firebase/config';
import { projectAuth, projectFirestore } from './firebase/config.js'

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { motion, AnimatePresence } from "framer-motion"
// import Masonry from 'react-masonry-css'



import "./App.css";
import Masonry from "@mui/lab/Masonry";
import { Fragment, useState, useEffect } from "react";
import data from "./data.json";

import { useInView } from 'react-intersection-observer';

// import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';


//tailwind Components
import Button from './TailwindComponents/Button'
import Modal from './TailwindComponents/Modal'


data.sort(() => 0.5 - Math.random());

function App() {

  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0,
    rootMargin: '3000px',
  });


  const { authIsReady, user } = useAuthContext()



  const [Artwork, setArtwork] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [myGallery, setMyGallery] = useState([]);

  const [loginModal, setLoginModal] = useState(false);
  const [isUser, setIsUser] = useState(false);

  const [curtain, setCurtain] = useState(false);


  const [tempId, setTempId] = useState();
  const [newData, setNewData] = useState(data.slice(0, 40));


  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setArtwork(false);
    setIsFavorite(false)
    setLoginModal(false)
  };


  
  


  const signInWithGoogle = async() => {
    const provider = new firebase.auth.GoogleAuthProvider();
    await projectAuth.signInWithPopup(provider);
    // setOptions(false)
    window.location.reload(false)
    setLoginModal(false)
    setIsUser(true)
  }

  


  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => { // detaching the listener
      if (user) {
        
        var ref = firebase.firestore().collection("users").doc(user.uid);
        ref.get().then((doc) => {
          if (doc.exists) {
            
            setTempId(doc.id)
          

            let favorites = doc.data().favorites
            
             for (let i = 0; i < favorites.length; i++) {
      var obj = data.find(x => x.id === favorites[i])
      
   
      obj.isFavorite = true
      myGallery.push(obj)

      // setMyGallery(prevObj => [...prevObj, obj]);
      
       }
            
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
              if (user.metadata.creationTime === user.metadata.lastSignInTime){
                firebase.firestore().collection("users").doc(user.uid).set({
                  username: user.displayName,
                  profilePic: user.photoURL,
                  
              })
              
              }
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
      } else {
          // No user is signed in...code to handle unauthenticated users. 
      }
  });
  
  return () => unsubscribe(); // unsubscribing from the listener when the component is unmounting. 
  
    
  }, [])

  const addToFavorites2 = (Artwork) => {
    if ((user) || (isUser)) {

      firebase.firestore().collection("users").doc(tempId).update({
        favorites: firebase.firestore.FieldValue.arrayUnion(Artwork.id)
    });
  
    Artwork.isFavorite = true

    let found = data.find(obj => obj.id === Artwork.id);
    found.isFavorite = true
    
    setIsFavorite(true)
    myGallery.push(Artwork)

    } else {
       setLoginModal(true)
    }
    
 
  }


  const removeFromFavorites = (Artwork) => {
    if ((user) || (isUser)) {

      firebase.firestore().collection("users").doc(tempId).update({
        favorites: firebase.firestore.FieldValue.arrayRemove(Artwork.id)
    });
  
    Artwork.isFavorite = false
    
    let found = data.find(obj => obj.id === Artwork.id);
    found.isFavorite = false
    
    setIsFavorite(false)
    let newArr = myGallery.filter(obj => obj.isFavorite === true);  

    setNewData(newArr)
    setMyGallery(newArr)
    } else {
       setLoginModal(true)
    }
    
 
  }


  


  const addToFavorites3 = (Artwork) => {
    if ((user) || (isUser)) {


      if(Artwork.isFavorite) {
        firebase.firestore().collection("users").doc(tempId).update({
          favorites: firebase.firestore.FieldValue.arrayRemove(Artwork.id)
      });
    
      Artwork.isFavorite = false
      
      let found = data.find(obj => obj.id === Artwork.id);
      found.isFavorite = false
      
      setIsFavorite(false)
      let newArr = myGallery.filter(obj => obj.isFavorite === true);
      setNewData(newArr)
      setMyGallery(newArr)

      

      } else if (!Artwork.isFavorite) {

        firebase.firestore().collection("users").doc(tempId).update({
          favorites: firebase.firestore.FieldValue.arrayUnion(Artwork.id)
      });
    
      Artwork.isFavorite = true
  
      let found = data.find(obj => obj.id === Artwork.id);
      found.isFavorite = true
      
      setIsFavorite(true)
      myGallery.push(Artwork)
      // setRefresher(Math.random())

      }

    

    } else {
       setLoginModal(true)
    }
    
 
  }

  useEffect(() => {
    
  test45()
  console.log(inView)
  // setCurtain(false)
    
  }, [inView])
  

  const test45 = () => {
    setCurtain(Math.random())

    const results = data.filter(({ id: id1 }) => !newData.some(({ id: id2 }) => id2 === id1));

    console.log(results);
   

    results.sort(() => 0.5 - Math.random());

    console.log(results.slice(0, 40))

    var arr2 = results.slice(0, 40)
    
    setNewData((prevVals) => [...prevVals, ...arr2])
    
    setTimeout(function() {
      setCurtain(false)
  }, 2000);
 
  }


  const test54 = () => {

   
    const results = data.filter(({ id: id1 }) => !newData.some(({ id: id2 }) => id2 === id1));

    console.log(results);
    

    // results.sort(() => 0.5 - Math.random());

    console.log(results.slice(0, 40))

    var arr2 = results.slice(0, 40)
    
    setNewData(arr2)
    
   
 
  }

  const backdrop = {
    visible: { opacity: 1, transition: { delay: 1.2 } },
    hidden: { opacity: 0 },
  }

  const backdrop2 = {
    visible: { opacity: 0, transition: { delay: 1.2 } },
    hidden: { opacity: 1 },
    transition: { duration: 1 }
  }


  

  return (
    <div className='overflow-x-hidden overflow-y-hidden bg-black select-none'>

{/* {curtain && <AnimatePresence><motion.div key={curtain} initial='hidden' animate='visible' exit='visible' variants={backdrop2} className="fixed w-screen h-screen backdrop-blur-lg "></motion.div></AnimatePresence>} */}

{/* <button ref={ref} onClick={test45} className="sticky top-0 px-4 py-2 bg-emerald-500">Load More</button> */}

{open && (<Modal onClick={handleClose} effect='blur' type='artwork'>




    <img className="max-h-[600px]" src={Artwork.originalImg} alt="" />

    <figcaption className="flex items-center justify-between px-2 mt-0.5">
      <h1 className="text-white">{Artwork.title}</h1>
      {(!Artwork.isFavorite) && <Icon onClick={() => {addToFavorites2(Artwork)}} className='cursor-pointer' icon="carbon:favorite" color="#fff" height="22" />}
      {(Artwork.isFavorite) && <Icon onClick={() => {removeFromFavorites(Artwork)}} className='cursor-pointer' icon="carbon:favorite-filled" color="#047857" height="22" />}
    </figcaption>

    <h2 className="px-2 text-white font-extralight">{Artwork.artist}</h2>

    
</Modal>)}

      
      {loginModal && <Modal effect='blur' type='login' onClick={() => setLoginModal(false)} >
        <Button type='primary' size='md'>Login with google</Button>
        <Button type='primary' size='md'>Login with Facebook</Button>
    </Modal>}


      <main className="z-10 pl-4 bg-black">
        
        <div className="flex flex-row-reverse px-4 mb-4 gap-x-3">
          
        {!user && <Fragment>
          <button onClick={signInWithGoogle} className="px-1 py-0.5 mt-2 bg-sky-300">Login with google</button>
          <button className="px-1 py-0.5 mt-2 bg-sky-300">Login with facebook</button>
            </Fragment>}

            {user && <Fragment>
              <h1 onClick={() => setNewData(data.slice(0, 80))} className='mr-2 text-lg text-white cursor-pointer'>Home</h1>
              <h1 onClick={() => setNewData(myGallery)} className='mr-2 text-lg text-white cursor-pointer'>my gallery</h1>
              <h1 onClick={() => {projectAuth.signOut(); window.location.reload(false)}} className='text-lg text-white cursor-pointer'>logout</h1>
              </Fragment>}
        </div>
       
        <AnimatePresence exitBeforeEnter>
        <Masonry columns={{ xs: 2, sm: 4, md: 5 }} spacing={2}>
          {newData.map((d, index) => (
            <motion.div key={d.title}  initial='hidden' animate='visible' exit='hidden' variants={backdrop}>
               <LazyLoadImage  alt={d.title} height={d.largeImgHeight} src={d.largeImg} width={d.largeImgWidth} />
             
              {/* <motion.img className='sticky' key={d.title}  initial='hidden' animate='visible' exit='hidden' variants={backdrop} onClick={() => { handleOpen(); setArtwork(d);}} height={d.largeImgHeight} width={d.largeImgWidth}  src={d.largeImg} alt="" /> */}
              <motion.h1  onDoubleClick={() => addToFavorites3(d)} className={`text-white cursor-pointer  ${d.isFavorite ? 'text-emerald-400' : ''}`}>{d.title}</motion.h1>
              <h1 className="text-white font-extralight">{d.artist}</h1>
            </motion.div>
          ))}
        </Masonry>
        </AnimatePresence>

{/* <Masonry
  breakpointCols={5}
  className="px-4 my-masonry-grid"
  columnClassName="my-masonry-grid_column">
  {newData.map((d, index) => (
            
             
              <img className='my-6' key={d.title}  initial='hidden' animate='visible' exit='hidden' variants={backdrop} onClick={() => { handleOpen(); setArtwork(d);}} height={d.largeImgHeight} width={d.largeImgWidth}  src={d.largeImg} alt="" />
            

          ))}
</Masonry> */}

        {/* <ImageList variant="masonry" cols={5} gap={8}>
  {newData.map((item) => (
    <ImageListItem key={item.img}>
      <img
        src={item.largeImg}
        srcSet={item.largeImg}
        alt={item.title}
        loading="lazy"
        width={item.largeImgWidth} 
        height={item.largeImgHeight}
      />

<h1  onDoubleClick={() => addToFavorites3(item)} className={`text-white cursor-pointer  ${item.isFavorite ? 'text-emerald-400' : ''}`}>{item.title}</h1>
              <h1 className="text-white font-extralight">{item.artist}</h1>
    </ImageListItem>
  ))}
</ImageList> */}


        {/* <ResponsiveMasonry
                columnsCountBreakPoints={{350: 2, 750: 3, 900: 5}}
                className="md:px-10"
            >
              <Masonry gutter={20} >
                {newData.map((d, index) => (
            <div>
              <img onClick={() => { handleOpen(); setArtwork(d);}} className="cursor-pointer" width={d.largeImgWidth} height={d.largeImgHeight} src={d.largeImg} alt="" />
              <h1 onDoubleClick={() => addToFavorites3(d)} className={`text-white cursor-pointer  ${d.isFavorite ? 'text-emerald-400' : ''}`}>{d.title}</h1>
              <h1 className="text-white font-extralight">{d.artist}</h1>
            </div>
          ))}
                </Masonry>
            </ResponsiveMasonry> */}

           


{/* <button ref={ref} onClick={test45} className="px-4 py-2 bg-emerald-500">Load More</button> */}

<button ref={ref} onClick={test54} className="px-4 py-2 bg-emerald-500">Load More</button>

{/* <button ref={ref} onClick={() => {test54(); window.scrollTo({ top: 0 });}} className="px-4 py-2 bg-emerald-500">Load More</button> */}


      </main>
      
    </div>
  );
}

export default App;





